<template>
  <section class="dtc-main-section mt-2 mr-2">
    <RuralNursingDistributionWatch></RuralNursingDistributionWatch>
    <RuralNursingDistributionFile></RuralNursingDistributionFile>
    <section class="search-block">
      <h5 class="big-title">偏護菁英-畢業生資料</h5>
      <div class="dtc-search">
        <b-input-group prepend="姓名">
          <b-input
            v-model.trim="searchName"
            placeholder="請輸入姓名"
            @keydown.enter="searchDb"
          ></b-input>
        </b-input-group>
        <b-input-group prepend="身分證字號">
          <b-input
            v-model.trim="searchId"
            placeholder="身分證字號"
            @keydown.enter="searchDb"
          ></b-input>
        </b-input-group>
        <b-input-group prepend="出生日期">
          <div class="search-birthday">
            <b-input
              v-model.trim="searchBirthday.year"
              placeholder="民國年"
              @keypress="isNumber($event)"
            ></b-input>
            <span class="dash">-</span>
            <b-input
              v-model.trim="searchBirthday.month"
              placeholder="月"
              @keypress="isNumber($event)"
              ref="searchBirthdayMonth"
            ></b-input>
            <span class="dash">-</span>
            <b-input
              v-model.trim="searchBirthday.day"
              placeholder="日"
              @keypress="isNumber($event)"
              ref="searchBirthdayDay"
            ></b-input>
          </div>
        </b-input-group>
        <b-input-group prepend="培育學校">
          <b-select
            :options="schoolObject"
            v-model="searchSchool"
            @change="searchDb"
          ></b-select>
        </b-input-group>
        <b-button variant="primary" @click="searchDb">進行查詢</b-button>
        <b-button variant="danger" @click="clearSearch">清除條件</b-button>
      </div>
      <div class="dtc-search">
        <b-input-group prepend="分發資料">
          <b-select
            :options="[{ text: '全部', value: null }, '已提供', '未提供']"
            v-model="searchDisData"
            @change="searchDb"
          ></b-select>
        </b-input-group>
        <b-input-group prepend="選填志願">
          <b-select
            :options="[{ text: '全部', value: null }, '已選填', '未選填']"
            v-model="searcOrder"
            @change="searchDb"
          ></b-select>
        </b-input-group>
        <b-input-group prepend="入學年">
          <b-input
            v-model.trim="searchEntryYear"
            placeholder="入學年(民國年)"
            @keypress="isNumber($event)"
          ></b-input>
        </b-input-group>
        <b-input-group prepend="畢業年">
          <b-input
            v-model.trim="searchEndYear"
            placeholder="畢業年(民國年)"
            @keypress="isNumber($event)"
          ></b-input>
        </b-input-group>
      </div>
      <div class="dtc-search">
        <b-input-group prepend="籍貫">
          <b-select
            :options="hometownObject"
            v-model="searchHometown"
            @change="searchDb"
          ></b-select>
        </b-input-group>
        <b-input-group prepend="分發服務醫院">
          <b-input
            v-model.trim="searchHospital"
            placeholder="分發服務醫院"
            @keydown.enter="searchDb"
          ></b-input>
        </b-input-group>
      </div>
    </section>
    <header class="dtc-grid-header my-dark">
      <div v-for="(item, i) in headers" :key="`headers${i}`" :title="item">
        {{ item }}
      </div>
    </header>
    <main
      class="dtc-grid-header2"
      v-for="(item, i) in items"
      :key="i"
      :style="
        i % 2 == 0 ? 'background-color: #EDEDED;' : 'background-color: #DDDDDD;'
      "
    >
      <div>{{ i + 1 }}</div>
      <div
        style="cursor: pointer;font-size: 1.6rem;padding-top:0px;"
        @click="editLocalData(item)"
      >
        <i class="fas fa-search-plus"></i>
      </div>
      <div
        style="cursor: pointer;font-size: 1.6rem;padding-top:0px;"
        @click="viewFile(item)"
      >
        <i class="fas fa-file-alt"></i>
      </div>
      <div
        :title="item.Distribution_Status"
        :style="item.Distribution_Status == '未提供' ? 'color:red' : ''"
      >
        {{ item.Distribution_Status }}
      </div>
      <div
        :title="item.OrderHospital_Status"
        :style="item.OrderHospital_Status == '未選填' ? 'color:red' : ''"
      >
        {{ item.OrderHospital_Status }}
      </div>
      <div :title="item.ServiceItem">
        {{ item.ServiceItem || "暫無資料" }}
      </div>

      <div :title="item.ServiceDetail">
        {{ item.ServiceDetail || "暫無資料" }}
      </div>
      <div :title="item.Name">
        {{ item.Name || "暫無資料" }}
      </div>

      <div :title="item.Identifier">
        {{ item.Identifier || "暫無資料" }}
      </div>

      <div :title="item.gender">
        {{ item.gender || "暫無資料" }}
      </div>
      <div :title="item.Cht_BirthDate">
        {{ item.Cht_BirthDate || "暫無資料" }}
      </div>

      <div :title="item.HospitalName">
        {{ item.HospitalName || "暫無資料" }}
      </div>
      <div :title="item.school">
        {{ item.school || "暫無資料" }}
      </div>
      <div :title="item.StartYear">
        {{ item.StartYear || "暫無資料" }}
      </div>
      <div :title="item.EndYear">
        {{ item.EndYear || "暫無資料" }}
      </div>
      <div :title="item.hometown">
        {{ item.hometown }}
      </div>
      <div :title="item.Home" class="note">
        {{ item.Home || "暫無資料" }}
      </div>
      <div :title="item.Mobile" class="note">
        {{ item.Mobile || "暫無資料" }}
      </div>
    </main>
    <footer class="dtx-footer">
      <b-pagination
        v-model="currentPageNum"
        :total-rows="rowCount"
        :per-page="pagingRowPerPage"
        aria-controls="dataTable1"
        align="center"
        class="mt-2"
      >
        <span slot="first-text">
          <i class="fas fa-fast-backward"></i>
        </span>
        <span slot="prev-text">
          <i class="fas fa-step-backward"></i>
        </span>
        <span slot="next-text">
          <i class="fas fa-step-forward"></i>
        </span>
        <span slot="last-text">
          <i class="fas fa-fast-forward"></i>
        </span>
        <span slot="page" slot-scope="{ page, active }">
          <b v-if="active">{{ page }}</b>
          <i v-else>{{ page }}</i>
        </span>
      </b-pagination>
      <b-select
        :options="rows"
        class="mt-2"
        v-model="pagingRowPerPage"
      ></b-select>
      <div class="mt-3">{{ totalCountStr }}</div>
    </footer>
  </section>
</template>

<script>
import queryString from "query-string";
import buildQuery from "odata-query";
import { store } from "@/store/global.js";
import moment from "moment";
import RuralNursingDistributionWatch from "./RuralNursingDistributionWatch";
import RuralNursingDistributionFile from "./RuralNursingDistributionFile";

const headers = [
  "序",
  "檢視",
  "附件",
  "分發資料",
  "志願選填",
  "服務情形",
  "服務現況",
  "姓名",
  "身分證字號",
  "性別",
  "出生日期",
  "分發服務醫院",
  "培育學校",
  "入學年",
  "畢業年",
  "籍貫",
  "連絡電話(市話)",
  "行動電話",
];
const zero = "T00:00:00";
const rows = [10, 20, 50];
const year = new Date().getFullYear() - 1911;
let years = new Array(8).fill().map((s, i) => year + 1 - i);
years.unshift({ value: null, text: "全部" });

export default {
  name: "downarea",
  data() {
    return {
      headers,
      items: [],
      keys: [],
      currentPageNum: 1,
      rowCount: 0,
      pagingRowPerPage: 20,
      year: null,
      search: true,
      rows,
      totalCountStr: "",
      years,
      searchName: "",
      searchId: "",
      searchGender: null,
      searchBirthday: {
        year: "",
        month: "",
        day: "",
      },
      searchSchool: null,
      searchEntryYear: null,
      searchEndYear: null,
      searchMajor: null,
      searchCategory: null,
      searchHometown: null,
      schoolObject: [],
      searchDisData: null,
      searcOrder: null,
      hometownObject: [],
      searchHospital: "",
    };
  },
  components: {
    RuralNursingDistributionWatch,
    RuralNursingDistributionFile,
  },
  computed: {},

  methods: {
    isNumber(evt) {
      evt = evt ? evt : window.event;
      const charCode = evt.which ? evt.which : evt.keyCode;
      if (charCode > 31 && (charCode < 48 || charCode > 57)) {
        evt.preventDefault();
      } else {
        return true;
      }
    },
    editLocalData(item) {
      store.editItem = { ...item };
      store.editItem.tab = 1;
      this.$bvModal.show("ruralNursingDistributionWatch");
    },
    viewFile(item) {
      store.editItem = { ...item };
      store.editItem.tab = 1;
      this.$bvModal.show("ruralNursingDistributionFile");
    },
    searchDb() {
      this.search = true;
      this.getData();
    },
    async clearSearch() {
      this.searchName = "";
      this.searchId = "";
      this.searchGender = null;
      this.searchSchool = null;
      this.searchEntryYear = null;
      this.searchEndYear = null;
      this.searchMajor = null;
      this.searchCategory = null;
      this.searchHometown = null;
      this.searchDisData = null;
      this.searcOrder = null;
      this.searchHospital = "";
      this.searchBirthday.year = "";
      this.searchBirthday.month = "";
      this.searchBirthday.day = "";

      this.getData();
    },
    async getData() {
      //odata3 qs
      const page = this.currentPageNum;
      const skip = (page - 1) * this.pagingRowPerPage;
      const top = this.pagingRowPerPage;
      const orderBy = this.orderBy;
      const obj = { top, skip, orderBy };
      let qs = buildQuery(obj); // + no + filter + filter2;
      qs = `${qs}&$filter=Category eq '2'`;

      if (this.searchName) {
        qs = this.$appendSubstringofFilter("Name", qs, this.searchName);
      }
      if (this.searchId) {
        qs = this.$appendSubstringofFilter("Identifier", qs, this.searchId);
      }

      if (
        Boolean(this.searchBirthday.year) &&
        Boolean(this.searchBirthday.month) &&
        Boolean(this.searchBirthday.day)
      ) {
        const date = `${this.searchBirthday.year}-${this.searchBirthday.month}-${this.searchBirthday.day}`;
        const startDate = moment(this.$usDate(date))
          .subtract(1, "days")
          .format("YYYY-MM-DD");
        const endDate = this.$usDate(date);
        qs = this.$appendTimeFilter("BirthDate", qs, startDate, endDate);
      }
      if (this.searchSchool) {
        qs = this.$appendSubstringofFilter("SchoolCode", qs, this.searchSchool);
      }
      if (this.searchDisData) {
        qs = this.$appendEqualFilter(
          "Distribution_Status",
          qs,
          this.searchDisData
        );
      }
      if (this.searcOrder) {
        qs = this.$appendEqualFilter(
          "OrderHospital_Status",
          qs,
          this.searcOrder
        );
      }

      if (this.searchEntryYear) {
        qs = this.$appendSubstringofFilter(
          "StartYear",
          qs,
          this.searchEntryYear
        );
      }
      if (this.searchEndYear) {
        qs = this.$appendSubstringofFilter("EndYear", qs, this.searchEndYear);
      }

      if (this.searchHometown) {
        qs = this.$appendSubstringofFilter("County", qs, this.searchHometown);
      }
      if (this.searchHospital) {
        qs = this.$appendSubstringofFilter(
          "HospitalName",
          qs,
          this.searchHospital
        );
      }

      try {
        const { Items, Count } = await window.axios.get(
          `StudentInfo/GetNurseFinish${qs}`
        );

        this.items = Items.map((s) => {
          s.type = +s.Category || "";
          s.name = s.Name || "";
          s.id = s.Identifier || "";
          s.gender = !Boolean(s.Gender) ? "" : s.Gender == "M" ? "男" : "女";
          s.school = this.schoolObject.find((k) => +k.value == +s.SchoolCode)
            ? this.schoolObject.find((k) => +k.value == +s.SchoolCode).text
            : "";
          s.hometown = this.hometownObject.find((k) => k.value == +s.County)
            ? this.hometownObject.find((k) => k.value == +s.County).text
            : "";
          return s;
        });

        this.rowCount = Count;
        this.totalCountStr = `共${Count} 筆`;
      } catch (e) {
        this.$bvToast.toast(`${e}`, {
          title: "系統資訊",
          autoHideDelay: 5000,
          variant: "danger",
        });
      }
    },
    async getAllSchool() {
      const arr = await window.axios.get("School/GetAll");
      this.schoolObject = arr.map((s) => ({
        value: s.SchoolCode,
        text: s.SchoolName,
      }));
      this.schoolObject.unshift({ value: null, text: "全部" });
    },
    async getHomeTown() {
      const arr = await window.axios.get("SysCode/Get?category=country");
      this.hometownObject = arr.map((s) => ({
        value: s.Code,
        text: s.Display,
      }));
      this.hometownObject.unshift({ value: null, text: "全部" });
    },
  },
  async mounted() {
    this.getAllSchool();
    this.getHomeTown();
    this.getData();
  },

  watch: {
    searchEntryYear(v) {
      if (+v < 0) {
        this.searchEntryYear = "0";
      }
      let value = v;
      if (value.substring(0, 1) == "0") {
        value = value.substring(1);
      }
      if (v.length > 3) {
        value = value.substring(1, 4);
      }
      const result = value;
      this.$nextTick(() => (this.searchEntryYear = result));
    },
    searchEndYear(v) {
      if (+v < 0) {
        this.searchEndYear = "0";
      }
      let value = v;
      if (value.substring(0, 1) == "0") {
        value = value.substring(1);
      }
      if (v.length > 3) {
        value = value.substring(1, 4);
      }
      const result = value;
      this.$nextTick(() => (this.searchEndYear = result));
    },
    "searchBirthday.year"(v) {
      if (+v < 0) {
        this.searchBirthday.year = "0";
      }
      let value = v;
      if (value.substring(0, 1) == "0") {
        value = value.substring(1);
      }
      if (v.length > 3) {
        value = value.substring(1, 4);
      }
      const result = value;
      this.$nextTick(() => (this.searchBirthday.year = result));
    },
    "searchBirthday.month"(v) {
      let value = v;
      if (+v < 0) {
        this.searchBirthday.month = 0;
      }
      if (value.substring(0, 1) == "0") {
        value = value.substring(1);
      }
      if (+v == 10) {
        value = 10;
      } else if (+v == 11) {
        value = 11;
      } else if (+v >= 12) {
        value = 12;
      }
      const result = value;
      this.$nextTick(() => (this.searchBirthday.month = result));
    },
    "searchBirthday.day"(v) {
      let value = v;
      if (+v < 0) {
        this.searchBirthday.day = 0;
      }
      if (value.substring(0, 1) == "0") {
        value = value.substring(1);
      }
      if (+v >= 31) {
        value = 31;
      }
      const result = value;
      this.$nextTick(() => (this.searchBirthday.day = result));
    },
    currentPageNum(v) {
      this.currentPageNum = v;
      this.getData().catch((e) => {});
    },
    pagingRowPerPage(v) {
      this.getData().catch((e) => {});
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
.dtc-main-section {
  // overflow-x: auto;
  padding: 12px;
  // position: relative;
  // height: 98vh;
}
.dtc-grid-header,
.dtc-grid-header2 {
  display: grid;
  grid-template-columns:
    80px repeat(10, 120px) 380px 240px 120px 120px 160px
    160px 140px;
  grid-auto-flow: column;
  text-align: center;
  border-right: 0px;

  > div {
    overflow: hidden;
    text-overflow: ellipsis;
    -webkit-line-clamp: 1;
    /* word-break: keep-all; */
    -webkit-box-orient: vertical;
    font-size: 14px;
    padding: 4px 0;
    border-right: 1px solid #dee2e5;
    border-bottom: 1px solid #dee2e5;
    display: -webkit-box;
    height: 36px;
  }
  > div:first-child {
    border-left: 1px solid #dee2e5;
  }
  > div:last-child {
    // border-right: none;
  }
}
.dtc-grid-header2 {
  > div {
    font-size: 16px;
    padding: 6px;
    padding-top: 7px;
  }
}

.my-dark {
  > div {
    // background: var(--gray);
    background: #424242;
    color: white;
  }
}
.dtc-link {
  color: var(--primary);
  cursor: pointer;
}
.dtc-search {
  display: grid;
  max-height: 40px;
  margin-bottom: 12px;
  grid-template-columns: repeat(2, 260px) 364px 364px repeat(32, max-content);
  grid-gap: 12px;
  > * {
    max-height: 44px;
  }
}
.dtx-footer {
  display: grid;
  grid-template-columns: max-content max-content max-content;
  justify-content: center;
  grid-gap: 12px;
}

.input-group-text {
  width: 120px;
  background: #0379fd;
  color: #fff;
}
.file-content {
  text-align: start;
  padding-left: 10px !important;
  .subject {
    cursor: pointer;
    font-weight: 900;
  }
  .content {
    color: #777;
  }
}
.search-block {
  height: 190px;
  margin-bottom: 10px;
  .big-title {
    background: #0379fd;
    padding: 5px;
    color: #fff;
    border-radius: 6px 6px 0 0;
  }
  .dtc-search,
  .dtc-search1 {
    padding-left: 10px;
  }
  background: #fbfcf9;
  border: 1px solid #bdbdbd;
  border-radius: 8px 8px 0 0;
}
.note {
  text-align: left;
  padding-left: 10px !important;
}
.search-birthday {
  display: grid;
  grid-template-columns: 80px 12.5px 70px 12.5px 70px;
  .dash {
    margin-top: 6px;
    text-align: center;
  }
}
</style>
