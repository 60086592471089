<template>
  <b-modal
    @shown="updateItem"
    title="新增、檢視附件檔案"
    id="ruralNursingDistributionFile"
    size="lg"
  >
    <b-container fluid>
      <main class="dtc-grid-RecordAdd">
        <b-input-group prepend="上傳身分證影本" style="grid-column: 1/-1;">
          <div class="custom-file-upload-out">
            <label for="file-upload-id" class="custom-file-upload">
              <span style="color: rgb(105 36 136);">
                <i class="fas fa-cloud-upload-alt fa-lg"></i>
              </span>
              <div>Upload File</div>
              <input
                id="file-upload-id"
                type="file"
                @change="fileIdChange"
                accept="image/jpeg"
                ref="inputIdFile"
              />
            </label>
          </div>
          <div class="mt-2 ml-2">
            <b-button
              variant="primary"
              class="float-right mr-1 ml-2"
              @click="addIdFile"
              >確定新增</b-button
            >
          </div>
          <div class="mt-2 ml-2">
            <b-button
              variant="danger"
              class="float-right mr-3"
              @click="removeIdFile"
              >移除</b-button
            >
          </div>
          <div style="padding-top:13px;padding-left:8px">{{ fileIdName }}</div>
        </b-input-group>
        <b-input-group prepend="上傳兵役文件" style="grid-column: 1/-1;">
          <div class="custom-file-upload-out">
            <label for="file-upload-millitary" class="custom-file-upload">
              <span style="color: rgb(105 36 136);">
                <i class="fas fa-cloud-upload-alt fa-lg"></i>
              </span>
              <div>Upload File</div>
              <input
                id="file-upload-millitary"
                type="file"
                @change="fileMillitaryChange"
                accept="image/jpeg"
                ref="inputMillitaryFile"
              />
            </label>
          </div>
          <div class="mt-2 ml-2">
            <b-button
              variant="primary"
              class="float-right mr-1 ml-2"
              @click="addMillitaryFile"
              >確定新增</b-button
            >
          </div>
          <div class="mt-2 ml-2">
            <b-button
              variant="danger"
              class="float-right mr-3"
              @click="removeMillitaryFile"
              >移除</b-button
            >
          </div>
          <div style="padding-top:13px;padding-left:8px">
            {{ fileMillitaryName }}
          </div>
        </b-input-group>
        <b-input-group prepend="上傳成績單文件" style="grid-column: 1/-1;">
          <div class="custom-file-upload-out">
            <label for="file-upload-score" class="custom-file-upload">
              <span style="color: rgb(105 36 136);">
                <i class="fas fa-cloud-upload-alt fa-lg"></i>
              </span>
              <div>Upload File</div>
              <input
                id="file-upload-score"
                type="file"
                @change="fileScoreChange"
                accept="image/jpeg"
                ref="inputScoreFile"
              />
            </label>
          </div>
          <div class="mt-2 ml-2">
            <b-button
              variant="primary"
              class="float-right mr-1 ml-2"
              @click="addScoreFile"
              >確定新增</b-button
            >
          </div>
          <div class="mt-2 ml-2">
            <b-button
              variant="danger"
              class="float-right mr-3"
              @click="removeScoreFile"
              >移除</b-button
            >
          </div>
          <div style="padding-top:13px;padding-left:8px">
            {{ fileScoreName }}
          </div>
        </b-input-group>
        <b-input-group prepend="上傳其他文件" style="grid-column: 1/-1;">
          <div class="custom-file-upload-out">
            <label for="file-upload-other" class="custom-file-upload">
              <span style="color: rgb(105 36 136);">
                <i class="fas fa-cloud-upload-alt fa-lg"></i>
              </span>
              <div>Upload File</div>
              <input
                id="file-upload-other"
                type="file"
                @change="fileOtherChange"
                accept="image/jpeg"
                ref="inputOtherFile"
              />
            </label>
          </div>
          <div class="mt-2 ml-2">
            <b-button
              variant="primary"
              class="float-right mr-1 ml-2"
              @click="addOtherFile"
              >確定新增</b-button
            >
          </div>
          <div class="mt-2 ml-2">
            <b-button
              variant="danger"
              class="float-right mr-3"
              @click="removeOtherFile"
              >移除</b-button
            >
          </div>
          <div style="padding-top:13px;padding-left:8px">
            {{ fileOtherName }}
          </div>
        </b-input-group>
        <div class="mt-2">
          <b-button
            variant="success"
            class="float-left mr-3"
            @click="combineFile"
            >合併檔案</b-button
          >
          <b-button
            variant="warning"
            class="float-left mr-3"
            @click="openCombinedFile"
            :disabled="!combineFileUrl"
            >檢視合併檔案</b-button
          >
        </div>
      </main>
    </b-container>

    <template v-slot:modal-footer>
      <div class="w-100">
        <b-button
          variant="info"
          class="float-right ml-3 mr-3"
          @click="$bvModal.hide('ruralNursingDistributionFile')"
          >關閉</b-button
        >
      </div>
    </template>
  </b-modal>
</template>

<script>
import { store } from "@/store/global.js";
import moment from "moment";
import { domainObject } from "@/constant.js";

export default {
  name: "ruralNursingDistributionFile",
  data() {
    return {
      item: {},
      variants: [
        "warning",
        "dark",
        "light",
        "warning",
        "danger",
        "info",
        "light",
        "dark",
      ],
      fileId: null,
      fileIdName: "",
      fileMillitary: null,
      fileMillitaryName: "",
      fileScore: null,
      fileScoreName: "",
      fileOther: null,
      fileOtherName: "",
      combineFileUrl: "",
      domainObject,
    };
  },
  computed: {},
  methods: {
    openCombinedFile() {
      let fullPath = `${this.domainObject.platformOriginal}${this.combineFileUrl}`;
      window.open(fullPath);
    },
    async beginGetFileUrl() {
      const api = `FileManagment/NurseFileMerge?Identifier=${this.item.id}`;
      const res = await window.axios.get(api);
      this.combineFileUrl = res ? res : "";
    },
    async combineFile() {
      const api = `FileManagment/NurseFileMerge?Identifier=${this.item.id}`;
      try {
        const res = await window.axios.get(api);
        this.combineFileUrl = res;
        this.$bvToast.toast(`合併成功!!`, {
          title: `檔案管理`,
          autoHideDelay: 5000,
          variant: "success",
        });
      } catch (e) {
        this.$bvToast.toast("合併失敗!! " + e, {
          title: `檔案管理`,
          autoHideDelay: 5000,
          variant: "danger",
        });
      }
    },
    fileIdChange(e) {
      this.fileIdName = e.target.files[0].name;
      this.fileId = e.target.files[0];
    },
    removeIdFile() {
      this.$refs.inputIdFile.value = null;
      this.fileIdName = "";
      this.fileId = null;
    },
    async addIdFile() {
      let form = new FormData();
      form.append("UploadFile", this.fileId);
      form.append("Category", "" + 1);
      form.append("Identifier", "" + this.item.id);

      const api = "FileManagment/NurseFileUpload";

      try {
        await window.axios.post(api, form);
        this.$bvToast.toast(`新增成功!!`, {
          title: `身分證影本`,
          autoHideDelay: 5000,
          variant: "success",
          // toaster: "b-toaster-bottom-full",
        });
      } catch (e) {
        this.$bvToast.toast("新增失敗!! " + e, {
          title: `身分證影本`,
          autoHideDelay: 5000,
          variant: "danger",
          // toaster: "b-toaster-bottom-full",
        });
      }
    },
    fileMillitaryChange(e) {
      this.fileMillitaryName = e.target.files[0].name;
      this.fileMillitary = e.target.files[0];
    },
    removeMillitaryFile() {
      this.$refs.inputMillitaryFile.value = null;
      this.fileMillitaryName = "";
      this.fileMillitary = null;
    },
    async addMillitaryFile() {
      let form = new FormData();
      form.append("UploadFile", this.fileMillitary);
      form.append("Category", "" + 2);
      form.append("Identifier", "" + this.item.id);
      const api = "FileManagment/NurseFileUpload";

      try {
        await window.axios.post(api, form);
        this.$bvToast.toast(`新增成功!!`, {
          title: `兵役文件`,
          autoHideDelay: 5000,
          variant: "success",
        });
      } catch (e) {
        this.$bvToast.toast("新增失敗!! " + e, {
          title: `兵役文件`,
          autoHideDelay: 5000,
          variant: "danger",
        });
      }
    },
    fileScoreChange(e) {
      this.fileScoreName = e.target.files[0].name;
      this.fileScore = e.target.files[0];
    },
    removeScoreFile() {
      this.$refs.inputScoreFile.value = null;
      this.fileScoreName = "";
      this.fileScore = null;
    },
    async addScoreFile() {
      let form = new FormData();
      form.append("UploadFile", this.fileScore);
      form.append("Category", "" + 3);
      form.append("Identifier", "" + this.item.id);
      const api = "FileManagment/NurseFileUpload";

      try {
        await window.axios.post(api, form);
        this.$bvToast.toast(`新增成功!!`, {
          title: `成績單文件`,
          autoHideDelay: 5000,
          variant: "success",
        });
      } catch (e) {
        this.$bvToast.toast("新增失敗!! " + e, {
          title: `成績單文件`,
          autoHideDelay: 5000,
          variant: "danger",
        });
      }
    },
    fileOtherChange(e) {
      this.fileOtherName = e.target.files[0].name;
      this.fileOther = e.target.files[0];
    },
    removeOtherFile() {
      this.$refs.inputOtherFile.value = null;
      this.fileOtherName = "";
      this.fileOther = null;
    },
    async addOtherFile() {
      let form = new FormData();
      form.append("UploadFile", this.fileOther);
      form.append("Category", "" + 4);
      form.append("Identifier", "" + this.item.id);
      const api = "FileManagment/NurseFileUpload";

      try {
        await window.axios.post(api, form);
        this.$bvToast.toast(`新增成功!!`, {
          title: `其他文件`,
          autoHideDelay: 5000,
          variant: "success",
        });
      } catch (e) {
        this.$bvToast.toast("新增失敗!! " + e, {
          title: `其他文件`,
          autoHideDelay: 5000,
          variant: "danger",
        });
      }
    },
    updateItem() {
      this.item = { ...store.editItem };
      this.removeIdFile();
      this.removeMillitaryFile();
      this.removeScoreFile();
      this.removeOtherFile();
      this.combineFileUrl = "";
      this.beginGetFileUrl();
    },
  },
  mounted() {},
  watch: {},
};
</script>

<style scoped lang="scss">
.dtc-grid-RecordAdd {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-column-gap: 6px;
}

p {
  font-size: 2em;
  text-align: center;
}
.input-group-text {
  width: 150px;
  background: #0379fd;
  color: #fff;
}

div.input-group.special-col > div.input-group-prepend > div.input-group-text {
  width: 130px;
}
div.input-group.dtc-bg > div.input-group-prepend > div.input-group-text {
  background: #646969;
  color: #ffffff;
}

.input-group {
  margin-bottom: 10px;
}

::v-deep
  #ruralNursingDistributionFile
  > .modal-dialog
  > .modal-content
  > .modal-header {
  background: #7e2ca3;
  color: #ffffff;
  .close {
    color: #ffffff;
  }
}

hr.new {
  border-top: 1px dashed #cacaca;
}
h4.title {
  font-size: 20px;
  font-weight: 700;
}
.sign-date {
  display: grid;
  grid-template-columns: 88px 12.5px 70px 12.5px 70px;
  .dash {
    margin-top: 6px;
    text-align: center;
  }
}
.custom-file-upload-out {
  border: 1px solid #ccc;
  padding: 6px 12px;
  .custom-file-upload {
    display: inline-block;
    padding-top: 6px;
    cursor: pointer;
    display: grid;
    grid-template-columns: max-content max-content;
    grid-gap: 10px;

    input[type="file"] {
      position: absolute;
      width: 1px;
      height: 1px;
      padding: 0;
      margin: -1px;
      overflow: hidden;
      clip: rect(0, 0, 0, 0);
      border: 0;
    }
  }
}
</style>
